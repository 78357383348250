import React from 'react';
import PropTypes from 'prop-types';
import Container from '../structure/container';
import { Link } from 'gatsby';
import {
  PagerWrapper,
  ButtonSpacer,
  LoadButton,
  Dots,
  NumbersWrapper,
  PageCell,
  CurrentCell,
} from './pager.css';

const Pager = ({
  previousPagePath,
  nextPagePath,
  numberOfPages,
  humanPageNumber,
  pathPrefix,
}) => (
  <Container>
    <PagerWrapper>
      <div>
        {!previousPagePath && <ButtonSpacer />}
        {previousPagePath && (
          <Link to={`${previousPagePath}/`}>
            <LoadButton className="newer">← Newer Posts</LoadButton>
          </Link>
        )}
      </div>
      <NumbersWrapper>
        {previousPagePath && humanPageNumber != 2 && (
          <Link to={`${pathPrefix}/`}>
            <PageCell>1</PageCell>
          </Link>
        )}
        {previousPagePath && humanPageNumber != 2 && <Dots>...</Dots>}
        {previousPagePath && (
          <Link to={`${previousPagePath}/`}>
            <PageCell>{humanPageNumber - 1}</PageCell>
          </Link>
        )}
        <CurrentCell>
          <p>{humanPageNumber}</p>
        </CurrentCell>
        {nextPagePath && (
          <Link to={`${nextPagePath}/`}>
            <PageCell>{humanPageNumber + 1}</PageCell>
          </Link>
        )}

        {nextPagePath && humanPageNumber != numberOfPages - 1 && (
          <Link to={`${pathPrefix}/${humanPageNumber + 2}/`}>
            <PageCell>{humanPageNumber + 2}</PageCell>
          </Link>
        )}
        {nextPagePath && humanPageNumber != numberOfPages - 1 && (
          <Dots>...</Dots>
        )}
        {nextPagePath && humanPageNumber != numberOfPages - 1 && (
          <Link to={`${pathPrefix}/${numberOfPages}/`}>
            <PageCell>{numberOfPages}</PageCell>
          </Link>
        )}
      </NumbersWrapper>
      <div>
        {!nextPagePath && <ButtonSpacer />}
        {nextPagePath && (
          <Link to={`${nextPagePath}/`}>
            <LoadButton className="older">Older Posts →</LoadButton>
          </Link>
        )}
      </div>
    </PagerWrapper>
  </Container>
);

Pager.propTypes = {
  previousPagePath: PropTypes.string.isRequired,
  nextPagePath: PropTypes.string.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  humanPageNumber: PropTypes.number.isRequired,
  pathPrefix: PropTypes.string.isRequired,
};

export default Pager;

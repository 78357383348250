import { Link } from 'gatsby';
import styled from 'styled-components';
import { barlowFont, barlowSpacing, dark, light } from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';
import { hoverOpacityEffect } from '../../styles/effects.css';
import { RowWrapper } from '../structure/row.css';

export const HeroWrapper = styled(RowWrapper)`
  ${MEDIA.MIN_TABLET`
    align-items: flex-start;
  `}
  padding-top: 40px;
  padding-bottom: 16px;
  ${MEDIA.TABLET`
    padding-top: 140px;
  `}
`;

export const HeroContent = styled.div`
  width: 100%;
  ${MEDIA.MIN_DESKTOP`
    max-width: 540px;
  `}
  ${MEDIA.MIN_TABLET`
    max-width: 50%;
    margin-right: 48px;
  `}
  ${MEDIA.TABLET`
    max-width: 540px;
    ${({ hasArticle }) => hasArticle && 'padding-bottom: 80px;'}
  `}
  flex-shrink: 0;
`;

export const BreadcrumbsWrapper = styled.div`
  margin-bottom: 8px;
  ${MEDIA.TABLET`
    margin-bottom: 16px;
    text-align: center;
  `}
  ${MEDIA.PHONE`
    margin-bottom: 8px;
  `}
`;

export const Breadcrumb = styled(Link)`
  ${hoverOpacityEffect}
  color: ${dark};
  font: 600 20px/1 ${barlowFont};
  letter-spacing: ${barlowSpacing};
  text-decoration: none;
  text-transform: uppercase;
`;

export const BreadcrumbSeparator = styled.svg`
  margin: 0 12px;
  vertical-align: baseline;
  position: relative;
  top: -1px;
  color: ${light};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  ${MEDIA.TABLET`
    max-width: 335px;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
  `}
`;

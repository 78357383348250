import styled from 'styled-components';
import {
  accentPrimary,
  accentSecondary,
  dark,
  medium,
  white,
} from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';

export const PagerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 64px;
  a {
    text-decoration: none;
  }
`;

export const NumbersWrapper = styled.div`
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  color: ${medium};
  > * {
    margin-right: 8px;
    ${MEDIA.PHONE`
      margin-right: 2px;
    `};
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const PageCell = styled.div`
  border-radius: 6px;
  padding: 12px;
  color: ${dark};
  border: 1px solid rgba(198, 199, 210, 0);
  transition: border 400ms ease-in-out, color 200ms ease;
  &:hover {
    color: ${accentPrimary};
    border: 1px solid rgba(198, 199, 210, 0.3);
  }
`;

export const Dots = styled.div`
  padding: 12px;
  ${MEDIA.PHONE`
    padding: 4px;
  `};
`;

export const CurrentCell = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(19, 214, 143, 0.3);
  padding: 12px;
  color: ${accentPrimary};
`;

export const ButtonSpacer = styled.div`
  width: 200px;
  ${MEDIA.PHONE`
    display: none;
  `};
`;

export const LoadButton = styled.div`
  color: ${white};
  background: ${accentSecondary};
  width: 100%;
  max-width: 200px;
  padding: 10px 40px 12px;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.292194px;
  border-radius: 8px;
  ${MEDIA.TABLET`
    font-size: 16px;
    padding: 10px 16px 12px;
 `};
  ${MEDIA.PHONE`
    display: none;
  `};
`;

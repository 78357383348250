import React from 'react';
import PropTypes from 'prop-types';
import StoreButtonApple from '../common/store-button/apple';
import StoreButtonGoogle from '../common/store-button/google';
import BlogCard from './card';
import {
  HeroWrapper,
  HeroContent,
  BreadcrumbsWrapper,
  Breadcrumb,
  BreadcrumbSeparator,
  ButtonWrapper,
} from './hero.css';
import { light } from '../../constants/theme';

const BlogHero = ({
  featuredArticle,
  children,
  campaign = 'blog',
  breadcrumbs = [],
}) => (
  <HeroWrapper>
    <HeroContent hasArticle={featuredArticle}>
      {breadcrumbs && (
        <BreadcrumbsWrapper>
          {breadcrumbs.map((breadcrumb, index, array) => (
            <React.Fragment key={breadcrumb.path}>
              <Breadcrumb to={breadcrumb.path} activeStyle={{ color: light }}>
                {breadcrumb.label}
              </Breadcrumb>
              {index < array.length - 1 && (
                <>
                  {/* eslint-disable prettier/prettier */}
                  <BreadcrumbSeparator width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentcolor" fillRule="evenodd" clipRule="evenodd" d="M0.784591 11.0716C1.13095 11.3747 1.65742 11.3396 1.96049 10.9932L5.84938 6.5488C6.1243 6.23461 6.1243 5.76548 5.84938 5.45129L1.96049 1.00685C1.65743 0.660483 1.13096 0.625384 0.784593 0.928453C0.438229 1.23152 0.403131 1.75799 0.706199 2.10435L4.11493 6.00004L0.706198 9.89573C0.403129 10.2421 0.438227 10.7686 0.784591 11.0716Z" />
                  </BreadcrumbSeparator>
                  {/* eslint-enable prettier/prettier */}
                </>
              )}
            </React.Fragment>
          ))}
        </BreadcrumbsWrapper>
      )}
      {children}
      <ButtonWrapper>
        <StoreButtonApple campaign={`badge-${campaign}-top`} hasMargin />
        <StoreButtonGoogle campaign={`google-badge-${campaign}-top`} />
      </ButtonWrapper>
    </HeroContent>
    {featuredArticle && <BlogCard isFeatured {...featuredArticle} />}
  </HeroWrapper>
);

BlogHero.propTypes = {
  featuredArticle: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  children: PropTypes.node,
  campaign: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default BlogHero;
